export const front_page = {
    title: `Healthcare Insurance Package Calculator`,
    title_abbr: `(HIPC)`,
    menu: {
        dashboard: `Dashboard`,
        documentation: `Documentation`,
        calculator: `Package Calculator`,
        login: `Login`
    },
    card1: {
        title: `Purpose`,
        body: `Automated calculation of prices for medical care and services reimbursed by the state.`
    },
    card2: {
        title: `Development`,
        body: `This tool was developed with technical assistance from ADB and within the framework of the "Technical Assistance for the Implementation of Comprehensive Health Insurance" component of the "Technical Assistance for Health System Reforms in the Republic of Armenia" grant program.`
    },
    card3: {
        title: `Availability`,
        body: `The tool is only available to users with appropriate permissions.`
    },
    footer: {
        copyright: 'All rights reserved',
        made_by: 'made by'
    }
};